@mixin inline-error-mixin {
  & .inline-error-container {
    margin-bottom: 3%;
    &.location-blocked {
      display: flex;
      background: $Alert-Yellow;
      border-radius: 4px;
      padding: 2%;
      font-family: $National2Regular;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: -0.1px;
      color: $Brand-Black;

      & img {
        padding-right: 10.04px;
        width: 29px;
        height: 25px;
      }
    }
  }
}
.schedule-order-form-sections {
  margin: 0 15%;
  @media #{$breakpoint-below-tablet} {
    margin: 0 2vw;
  }

  .schedule-form-subsection-header {
    font-family: $National2Condensed;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: $Brand-Black;
    margin-top: 3vh;
  }

  .store-cannot-deliver-error {
    display: flex;
    background: $Red-Orange5;
    border-radius: 4px;
    padding: 2%;
    font-family: $National2Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.1px;
    color: $Brand-Black;

    & .store-cannot-deliver-icon {
      padding-right: 10.04px;
      width: 29px;
      height: 25px;
    }
  }

  .schedule-form-subsection {
    position: relative;
    .flat-num{
      color: #777474 !important;
    }
    .schedule-order-subsection-value {
      background-color: transparent;
      padding-top: 2vh;
      font-family: $National2Regular;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 24px;
      color: $Brand-Black;
      padding-right: 12%;
      padding-bottom: 1vh;
      width: 100% !important;
    }

    .border-bottom {
      border-bottom: 1px solid #e3e3e3;
    }

    .changeOrEditOrderType {
      position: absolute;
      top: 0;
      right: 0;
      text-decoration: underline;
      padding-top: 1.2vh;
      text-underline-offset: 1px;
      text-decoration-skip-ink: none;
      @-moz-document url-prefix() {
        text-underline-offset: 2px;
      }

      a {
        color: $Brand-Black;
        text-decoration: none;
        background-color: transparent;
        font-family: $National2Regular;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 22px;
        cursor: pointer;
      }
    }
  }

  .schedule-order-description-value {
    .schedule-order-subsection-secondary {
      font-weight: normal;
      padding-top: 0;
      padding-bottom: 0;

      &.flat-num {
        color: $Brand-Black !important;
      }
    }
    .border-bottom {
      margin-top: 8px;
    }
  }

  @include inline-error-mixin;
}
.timebased-inline-message {
  display: flex;
  width: 70%;
  flex-direction: row;
  padding: 16px;
  height: 56px;
  margin-top: 4%;
  margin-left: 108px;
  background: #fff6e3;
  border-radius: 4px;
  .img {
    height: 16px !important;
    width: 16px !important;
  }
  @media #{$mobile-only} {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 16px;
    width: 98%;
    height: 56px;
    background: #fff6e3;
    border-radius: 4px;
    margin-left: 0px;
  }

  .storeErrorMessage {
    width: 542px;
    height: 24px;
    font-family: $National2Regular;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: -0.1px;
    color: #202124;
    padding-left: 3%;

    @media #{$mobile-only} {
      height: 24px;
      // padding-left: 14%;
      font-family: $National2Regular;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 120%;
      letter-spacing: -0.1px;
      color: #202124;
    }
  }
}
.scheduleOrder-button-container {
  margin: 0 15%;
  display: flex;
  justify-content: center;
  text-align: center !important;
  margin-top: 5vh;
  @media #{$breakpoint-below-tablet} {
    margin: 0 2%;
    margin-top: 6.07vh;
    flex-direction: column;
  }
}
.button-contained[data-type=secondary][data-inactive=true]{
  @media #{$breakpoint-below-tablet} {
  width:unset;
  }

}
.date-time-section {
  display: flex;

  @media #{$breakpoint-below-tablet} {
    display: block;
  }
  .date-time-dropdown {
    width: 48%;

    @media #{$breakpoint-below-tablet} {
      width: 100%;
    }
    .downarrow-imgs {
      height: 24px;
      width: 11px;
    }
    & .dropdown-title {
      font-family: $National2Regular;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 22px;
      color: $Dark-Gray;
    }
    & .dropdown-value {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid $Dark-Gray;
      font-family: $National2Regular;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 24px;
      cursor: pointer;
      padding-right: 10px;
    }

    &.custom-dropdown-error {
      & .dropdown-value {
        border-bottom-color: $Red-Orange;
      }
    }

    .dropdown-error {
      display: flex;
      width: 100%;
      padding: 16px 16px 16px 0;
      height: 56px;
      border-radius: 4px;

      .store-cannot-deliver-icon {
        margin-top: 3px;
        height: 16px;
        width: 16px;
      }

      .dropdown-error-message {
        color: $gray-lightbg;
        padding-left: 8px;
        font-size: 14px;
      }
    }
  }

  .date-time-dropdown:first-child {
    margin: 0 auto 0 0;
  }
  .date-time-dropdown:nth-child(2) {
    margin: 0 0 0 auto;
    @media #{$breakpoint-below-tablet} {
      margin: 3% 0 0 auto;
    }
  }
  .options-container {
    position: relative;
    width: 100%;
    & ul {
      padding: 0% 0% 0% 0%;
      z-index: 1;
      background-color: $white;
      position: absolute;
      width: 100%;
      max-height: 100%;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 12px;
      }
      &::-webkit-scrollbar-thumb {
        width: 5px;
        background: $Brand-Black;
        border-radius: 15px;
        background-clip: content-box;
        border: 5px solid transparent;
      }

      & li {
        list-style: none;
        font-family: $National2Regular;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        cursor: pointer;
        padding-top: 1%;

        &:hover {
          background-color: #4D4D50;
        }
      }
    }

    & .disabledOption {
      background-color: $Inactive-Gray;
      opacity: 0.3;
      cursor: not-allowed;
      pointer-events: none;
    }
  }
}
.date-time-section-au{
  .options-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    height: 224px;
    z-index: 1;
    & ul {
      padding: 2% 2% 2% 3%;
    }
    & li {
      &:hover {
        background-color: #f8f7f5 !important;
    }
  }
  }
}

.date-time-section-ca {
  flex-direction: column;
  .date-time-dropdown {
    width: 100%;

    &--order-capacity {
      .options-container {
        background: $white;

        & ul li {
          background: $white;
          color: $Brand-Black;
        }

        & .unavailableOption {
          color: $grey;
        }
      }
    }
  }
  .date-time-dropdown:first-child {
    margin: 0 0 32px 0;
  }
  .date-time-dropdown:nth-child(2) {
    margin: 0 0 0 0;
    @media #{$breakpoint-below-tablet} {
      margin: 3% 0 0 0;
    }
  }
  .options-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    height: 224px;
    top: calc(50% - 224px / 2 + 135.5px);
    left: 0%;
    right: 0%;

    background: #202124;

    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.05);
    border-radius: 0px 0px 4px 4px;

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    z-index: 1;

    & ul {
      overflow-y: scroll;
      overflow-x: hidden;
      max-height: 100%;
      &::-webkit-scrollbar {
        display: none;
        width: 12px;
      }
      &::-webkit-scrollbar-thumb {
        width: 5px;
        background: $Brand-Black;
        border-radius: 15px;
        background-clip: content-box;
        border: 5px solid transparent;
      }

      & li {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 4px 8px 4px 16px;
        gap: 10px;

        width: 100%;
        height: 32px;

        background: #202124;

        flex: none;
        order: 0;
        flex-grow: 1;
        color: white;

        &:hover {
          margin: 0%;
          width: 100%;
        }
      }
    }
    & .disabledOption {
      opacity: 0.7;
      cursor: not-allowed;
      pointer-events: none;
    }
  }
}

.store-cannot-schedule-error {
  background: #ffeeee;
  margin: unset;
  width: 100%;

  &.timebased-inline-message .storeErrorMessage {
    width: 100% !important;
  }
}

